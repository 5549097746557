import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import axios from 'axios'
import { IssueTypes } from '@/globalVariables'
import { IndexIssue, Issue, NewIssue } from '@/types/service/issue'
import router from '@/router'
import { notification } from 'ant-design-vue'
import SecureLS from 'secure-ls'

interface LevelsWithRooms {
  [id: string]: { name:string, rooms: { id:number|null, name:string }[] };
}

export const issueStore = defineStore('issue', () => {
  const loading = ref(false)
  const issues = ref<IndexIssue[]>([])
  const deliveryIssues = ref<IndexIssue[]>([])
  const issue = ref<Issue|null>(null)
  const building = computed(() => new SecureLS({ isCompression: false }).get('building'))
  const levelsWithRooms = ref<LevelsWithRooms|[]>([])
  const elements = ref<{ id: number|null, name_sales:string }[]>([])

  function getIssues () : void {
    loading.value = true
    axios.get(process.env.VUE_APP_API_URL + '/issues').then((r) => {
      issues.value = r.data.data.filter((i:IndexIssue) => {
        return i.type === IssueTypes.ISSUE || i.type === IssueTypes.RENTAL
      })
      deliveryIssues.value = r.data.data.filter((i:IndexIssue) => {
        return i.type === IssueTypes.DELIVERY
      })
    }).catch(() => {
      notification.error({
        message: 'Fout tijdens ophalen van uw servicemeldingen!',
        description: 'Er is helaas iets mis gegaan. Probeer het later nog een keer.',
        duration: 0
      })
    }).finally(() => {
      loading.value = false
    })
  }

  function getIssue (id:string|string[]) : void {
    loading.value = true
    axios.get(process.env.VUE_APP_API_URL + '/issues/' + id).then((r) => {
      issue.value = r.data
    }).catch(() => {
      router.push({ name: 'Service' })
      notification.error({
        message: 'Fout tijdens ophalen van servicemelding!',
        description: 'Er is helaas iets mis gegaan. Probeer het later nog een keer.',
        duration: 0
      })
    }).finally(() => {
      loading.value = false
    })
  }

  function storeIssue (issue:NewIssue) : void {
    loading.value = true
    const serviceType = 1

    const formData = new FormData()
    formData.append('type', serviceType.toString())
    formData.append('title', issue.title || '')
    formData.append('body', issue.body || '')
    formData.append('room_id', issue.room_id?.toString() || '')
    formData.append('element_id', issue.element_id?.toString() || '')
    formData.append('contact_name', issue.contact_name || '')
    formData.append('contact_phone', issue.contact_phone || '')
    formData.append('contact_email', issue.contact_email || '')

    if (issue.building_id !== null) {
      formData.append('building_id', issue.building_id.toString())
    }

    if (issue.tenant) {
      formData.append('tenant[name]', issue.tenant.name || '')
      formData.append('tenant[phone]', issue.tenant.phone || '')
      formData.append('tenant[email]', issue.tenant.email || '')
    }

    issue.files.forEach((file:File) => {
      formData.append('files[]', file)
    })

    // Post the form data to the API.
    axios.post(process.env.VUE_APP_API_URL + '/issues', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(() => {
        router.push({ name: issue.building_id === null ? 'Service' : 'Global/Issues' })
        notification.success({
          message: 'Servicemelding aangemaakt.',
          description: 'Uw servicemelding is succesvol aangemaakt. Wij komen zo snel mogelijk met een reactie.',
          duration: 5
        })
      }).catch(() => {
        notification.error({
          message: 'Servicemelding NIET aangemaakt!',
          description: 'Er is helaas iets mis gegaan. Probeer het later nog een keer.',
          duration: 0
        })
      }).finally(() => {
        loading.value = false
      })
  }

  function getRooms (buildingId:number|null = null) : void {
    const bId = buildingId !== null ? buildingId : building.value.id
    axios.get(process.env.VUE_APP_API_URL + '/building/' + bId + '/rooms').then((r) => {
      // Add default room.
      const levels:LevelsWithRooms = { 999: { name: 'Algemeen', rooms: [{ id: null, name: 'Algemene ruimte' }] } }
      r.data.forEach((room:{ id:number, level:number, position:number, name:string }) => {
        if (levels[room.level] === undefined) {
          levels[room.level] = { name: room.level === 0 ? 'Begane grond' : room.level + 'e verdieping', rooms: [] }
        }
        levels[room.level].rooms.push({ id: room.id, name: room.level + '.' + room.position + ' - ' + room.name })
      })

      levelsWithRooms.value = levels
    })
  }

  function getElements (elementId:number|null, buildingId:number|null = null) : void {
    if (elementId) {
      const bId = buildingId !== null ? buildingId : building.value.id
      axios.get(process.env.VUE_APP_API_URL + '/building/' + bId + '/room/' + elementId + '/elements').then((r) => {
        elements.value = r.data
      })
    } else {
      elements.value = []
    }
  }

  return { loading, issues, deliveryIssues, issue, building, levelsWithRooms, elements, getIssues, getIssue, storeIssue, getRooms, getElements }
})
